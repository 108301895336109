@import "https://cdnjs.cloudflare.com/ajax/libs/foundation/6.3.0/css/foundation.min.css"; /* Using a string */

.sky-bg  {
  background-image: url('../../assets/images/page-1/Clouds.gif');
  background-size:cover;
  height: 100vh;    
  position: relative; 
  overflow: hidden;     
}

@-webkit-keyframes slidein {
from {background-position: top; background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}
}

@keyframes slidein {
from {background-position: top;background-size:3000px; }
to {background-position: -100px 0px;background-size:2750px;}

}

.center
{
display: flex;
align-items: center;
justify-content: center;
position: absolute;
margin: auto;
top: 0;
right: 0;
bottom: 0;
left: 0;
/* background: rgba(75, 75, 250, 0.3); */
border-radius: 3px;
}
.center h1{
text-align:center;
color:white;
font-family: 'Source Code Pro', monospace;
text-transform:uppercase;
}


/* sign up and signin page  */

.signup-bg{
  background-image: url('../../assets/images/page-1/Clouds.gif');
  background-size:cover;
  height: 100vh;    
  position: relative;      
  overflow: hidden;
}

.school-signup-bg{
  background-image: url('../../assets/images/page-1/Clouds.gif');
  background-size:cover;
  height: 100%;    
  position: relative;  
  height: 100vh;
  overflow-x: hidden;    
}

.school-signup-bg-new{
  background-image: url('../../assets/images/page-1/Clouds.gif');
  background-size:cover;
  height: 100vh;    
  position: relative; 
}

.form-coco-trees {
  top: -46%;
  z-index: -1;
}

.signup-dress-code{
  top: 0;
  right: 0;
}

.leaf-green{
  top: 133%;
  right: 23%;
  z-index: 19;
}

.welcome-scrappy{
  position: absolute;
  top: -24%;
  z-index: -1;
}

.waves-ocean{
  position: absolute;
  top: 49%;
  right: 0;
}

.notebook-clibs{
  top: -2%;
  right: -96%;
}

.flower-left-signup-television{
  position: absolute;
  bottom: 0;
}

.flower-left-signup-robo {
  position: absolute;
    bottom: 0;
    left: -19%;
    z-index: 1;
}

.flower-left-signup-football{
  position: absolute;
  bottom: -7%;
  left: -5%;
  z-index: 0;
}

.flower-left-signup-tennisball{
  position: absolute;
    bottom: -14%;
    left: 49%;
    z-index: -1;
}

.flower-left-signup-baseball{
  position: absolute;
  bottom: 10%;
  left: -3%;
  z-index: 1;
}

.field-notes{
  position: absolute;
  top: 47%;
  right: -55%;
  z-index: -2;
}

.signup-pink-football{
  width: 155px;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 35%;
  left: 52%;
}

.globe-img-box{
  position: absolute;
  top: 28%;
  right: 5%;
}
.globe-img-box img{
  width: 70%;
}
.globe-junk-box{
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
}

.globe-tree-box{
  position: absolute;
  top: 10%;
  left: 0;
}

.globe-playtv-box{
  position: absolute;
  top: 72%;
  left: 33%;
}

.globe-signup-box{
  position: absolute;
  top: 0%;
  left: 52%;
}

.globe-tv-slider-box{
  position: absolute;
  top: 30%;
  left: 7%;
}

.globe-tv-slider-box img{
  width: 80%;
}

.globe-scr-football-box{
  position: absolute;
  top: 38%;
  left: 2%;
}

.globe-arrow-box{
  position: absolute;
  top: 14%;
  left: 68%;
}




.signup-btn-img{
  position: absolute;
  top: 48%;
  left: 35%;
}


.video-box{
  top: 16%;
  left: 28%;
}

.play-city-video{
  width: 198px;
  height: 188px;
  border-bottom-left-radius: 33px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 52px;
  border-top-right-radius: 65px;
}


/* Home Page css start*/
.bg-sky{
  background-image: url('https://scrappy-afe2cd64181944-prod.s3.ap-south-1.amazonaws.com/public/scrappy-news/clouds-home.gif');
  background-size:cover;
  width: 100vw;
  height: 100vh; 
  position: relative;      
  overflow: hidden;
  background-position: bottom;
}

.bg-sky-leaderboard{
  background-image: url('../../assets/images/leaderboard/challenges-bg.gif');
  background-size:cover;
  width: 100vw;
  height: 100vh; 
  position: relative;      
  overflow-x: hidden;
  background-position: center;
}

.bg-sky-leaderboard-challenges{
  background-image: url('../../assets/images/leaderboard/challenges-bg.gif');
  background-size:cover;
  width: 100vw;
  height: 100vh; 
  position: relative;      
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: center;
}

.leaderboard-top{
  top: 5%;
}

.bg-sky-leaderboard-flow{
  overflow-y: scroll;
}

.leader-board-box {
  top: 1%;
  right: 27%;
  position: fixed;
  z-index: 2;
}

.leader-board-box-choice {
  width: 1100px;
}

.news-home{
  background-image: url('../../assets/images/page-1/newsroom-home.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  background-position-y: bottom;
  position: absolute;
  bottom: -4%;
  background-size: contain;
}

.coconut-tree{
  background-image: url('https://scrappy-afe2cd64181944-prod.s3.ap-south-1.amazonaws.com/public/scrappy-news/trees-home.gif');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  background-position-y: top;
}

.coconut-trees{
  background-image: url('../../assets/images/page-1/Trees.gif');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  position: absolute;
  bottom: -9%;
}
.coconut-trees-about{
  background-image: url('../../assets/images/page-1/trees-wave.gif');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  position: absolute;
  bottom: -58%;
}


.flag-image{
  position: absolute;
    z-index: 1;
    left: 351%;
    width: 266px;
    top: -9%;
}

.stream-tv{
  position: absolute;
  right: -9%;
  top: -0%;
  z-index: 1;
}

.img-ladder{
  position: absolute;
  right: 61%;
  bottom: 1%;
  z-index: 1;
}

.img-challenge{
  position: absolute;
  top: 22%;
  left: 2%;
  z-index: 1;
  width: 141%;
}

.right-flower{
  position: absolute;
  right: -4%;
  top: 0;
}
.left-flower{
  position: absolute;
  left: -2%;
  top: -56%;
  transform: rotate(189deg);
}
.img-ladder-container{
  position: relative;
}

.img-scooter-box{
  position: relative;
}

.img-scooter{
  position: absolute;
  left: 8%;
  bottom: 0;
  z-index: 2;
}

.img-scooter-bike{
  position: absolute;
  left: 16%;
  bottom: 0%;
  z-index: 2;
}

.img-playball{
  position: absolute;
  left: 53%;
  bottom: 88px;
  z-index: 2;
}

.img-playcity{
  position: absolute;
  bottom: -2%;
  right: 20%;
}

.coconut-fall{
  position: absolute;
  bottom: 0;
  left: 46%;
}


.coconut-fall-home{
  position: absolute;
  bottom: 10%;
  left: 4%;
  z-index: 2;
}

.store-scrappy{
  position: absolute;
  bottom: -2%;
  right: -3%;
}


.bg-green{
  position: absolute;
}


.bg-green{
  background-image: url('../../assets/images/page-1/grass-field.png');
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -12%;
  background-size: 100% 100%;
  z-index: 0;
}

.newshome-box{
  position: relative;
}

.img-ladder{
  position: absolute;
  bottom: 9% !important;
  margin-top: 136px;
  z-index: 0;
}

.kat-green{
  position: absolute;
  bottom: 0;
  left: 35%;
  z-index: 1;
}

.door-flower{
  position: absolute;
  bottom: 18%;
  right: 30%;
}

.img-flowers{
  position: absolute;
  bottom: 10%;
  left: 24%;
}


/* signup page  start */

.bg-ground{
  background-image: url('../../assets/images/page-3/signup-ground.png');
  background-size: cover;
  width: 100%;
  height: 35vh;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}

.ground-box{
  position: relative;
}

.signup-home-img {
  position: absolute;
  bottom: -16px;
}

.home-relative{
  position: relative;
  width: 100vw;
}

.thread-box-one{
position: absolute;
z-index: 1;
bottom: 55%;
width: 100vw;
}

.thread-box-two{
position: absolute;
z-index: 0;
bottom: 38%;
width: 100vw;
}

.robo-moving{
  position: absolute;
  bottom: 41%;
  left: 74%;
}

.flower-left-signup{
  position: absolute;
  left: -5%;
  top: -104px;
  transform: rotate(4deg);
}

.flower-right-signup{
  position: absolute;
  right: -3%;
}

.home-small{
  width: 35vw !important;
}

.football-round {
  position: absolute;
  right: -20%;
  bottom: 46%;
  width: 117px;
}

.signup-coco-tree{
  position: absolute;
    right: 0%;
    bottom: -2%;
    width: 26%;
}

.shirt-anime {
  position: absolute;
  right: 60%;
  top: 78%;
  z-index: 1;
}

.shirt-anime-two{
  position: absolute;
  left: 13%;
  top: 78%;
  z-index: 1;
}

.shirt-anime-three{
  position: absolute;
  left: 33%;
  top: 70%;
  z-index: 1;
}

.shirt-anime-one{
  position: absolute;
  right: 11%;
  z-index: 6;
  top: 8%;
}

.form-area{
  position: absolute;
  right: 31%;
  z-index: 9;
  top: 8%;
}

.form-area-container{
  position: relative;
}

.form-paper{
  position: absolute;
  top: 0;
  left: 12%;
}

.custom-form{
    background: none !important;
    border: none !important;
    border-bottom: 1px solid #00000063 !important;
    border-radius: 0px !important;
    outline: none !important;
}

.submit-btn{
  position: absolute;
    left: 18%;
    bottom: -34%;
}

.submit-btn-text{
  position: relative;
}

.submit-btn-text p{
  position: absolute;
  top: 25%;
  left: 37%;
  font-weight: 700;
}

.form-paper{
  margin-top: 40px;
}

.otp-input-fields{
  position: relative;
  margin-right: 1%;
}
.otp__digit{
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-input-fields input[type=number] {
  -moz-appearance: textfield;
}
.submit-btn-login{
  bottom: -20%;
}

.forgot-password{
  bottom: -30%;
  position: absolute;
  right: 14%;
  font-weight: bold;
}

.submit-btn-reg{
  bottom: -28%;
}

.bg-silver{
  background-image: url('../../assets/images/signup-school/Rectangle.png');
  width: 100%;
  height: auto;
  background-size: cover;
}

.profile-cards-bg{
  background-color: #E2312C;
}

.bg-green-profile-card{
  background-color: green;
  width: 100%;
  height: 40px;
}
.bg-yellow-profile-card{
  background-color: rgb(224, 192, 9);
  width: 100%;
  height: 40px;
}

.robo-msg{
  width: 300px !important;
}

.bg-profilecard-red{
color: E2312C;
}
/* signup page  end */


/* signupschool-start  */
.signup-right-tree {
  width: 271px;
  position: absolute;
  right: 0%;
  top: -306%;
  z-index: -1;
}

.scrappy-profile {
  top: 33%;
  left: 24%;
}

/* .scrappy-signup-btn{
  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../images/signup-school/red-bg.png');
} */

.school-palm-tree {
    position: absolute;
    top: 0%;
    left: -22%;
    width: 346px;
    height: 200px;
}

.robo-img {
  position: absolute;
  bottom: 12%;
  right: -13%;
    width: 209px;
}

.cycle-img {
  bottom: 11%;
  left: 36%;
  width: 190px;
  position: absolute;
}


.scrappy-signup-title{
  top: 34%;
    font-weight: 700;
    font-size: 17px;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.fsb-btn-one{
  background-image: url('../images/signup-school/red-bg.png');
  background-size: 100% 100%;
  min-width: 350px;
}

.fsb-btn-one label{
  font-size: 16px;
}

.fsb-btn-two{
  background-image: url('../images/signup-school/yellow-bg.png');
  background-size: 100% 100%;
  min-width: 350px;
}

.scrappy-signup-btn-two{
  position: absolute;
  top: 50%;
  left: 50%;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fsb-para{
  font-size: 16px;
}

.scrappy-signup-btn-one{
  position: absolute;
  top: 50%;
  left: 50%;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-center{
  position: absolute;
  top: 10%;
  left: 25%;
}

.form-center-map{
  position: absolute;
  top: -1%;
  left: 12%;
  width: 77%;
}

.form-center-box{
  top: 18%;
  left: 12%;
  width: 77%;
}

.form-center-thankyou{
  top: 26%;
  left: 12%;
  width: 77%;
}

.form-bg-none {
  background-color: #fcf6f60a !important;
  border: none !important;
  border-bottom: 1px solid #00000085 !important;
  border-radius: 0px !important;
}

/* signupschool-end  */

.slider-img-card{
  top: 16%;
  left: 6%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ps-bg{
  position: relative;
}

.gold-bottom{
  position: absolute;
  bottom: 0;
}

.user-profile-img-container {
  position: absolute;
  top: 26%;
  left: 31%;
}

.user-profile-description {
  position: absolute;
  bottom: 11%;
  left: 27%;
}
.user-profile-description p{
  margin-bottom: 0px;
  color: #fff;
  font-weight: bold;
}

.close-icon {
  position: absolute;
  right: 0;
}

.play-city-absolute{
  position: absolute!important;
  bottom: -22%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-city-nav{
  z-index: 3;
}

.play-city-globe-img{
  width: 100%;
  z-index: 1;
  position: relative;
}


.play-globe-arrow-box {
  top: -16%;
  right: 23%;
  top: 17%;
  width: 96px;
}

.play-city-globe-img-box {
  margin-top: 70px;
}

/* .globe-signup-box-new {
  position: absolute;
    top: -41%;
    left: 0%;
    z-index: 1 !important;
} */

.globe-scr-football-box {
  position: absolute;
  top: 27%;
  left: -22%;
}

.play-city-tv{
  width: 703px !important;
}

.flag-image-play-city{
  position: absolute;
  z-index: 0;
  left: 17.5%;
  bottom: 50%;
  width: 65%;
}

.news-home-play-city {
  background-image: url('../../assets/images/page-1/newsroom-home.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  position: absolute;
  bottom: -65%;
}

/* stream-bg  */
.stream-bg{
  background-image: url('../../assets/images/play-city/stream-tv-bg.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position-x: center;
  background-size: 100% 100%;
  position: absolute;
}

.google-play-img {
  width: 200px;
  position: absolute;
  bottom: 4%;
  right: 11%;
}

.scrappy-video-box{
  top: 13%;
  left: 9%;
}

.stream-video{
  height: 50vh;
}

.stream-parent{
  height: 50vh;
}

.scrappy-video-box{
  height: 50vh;
}

.stream-video-bg {
  border: 6px solid #fff;
  border-radius: 4px;
  float: right;
}

.slv-box-img{
  width: 100%;
  object-fit: contain;
}

.slv-box-right h6{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
}

.slv-box-right p{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.stream-list-view {
  background-color: #fff;
  border-radius: 12px;
  cursor: pointer;
}

.stream-list-view {
  height: 350px;
  overflow-y: scroll;
}

.slv-box-left img{
  height: 72px;
  border-radius: 6%;
}
.scrappy-shop-img-box {
  margin-top: -62px;
  width: 80%;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.stream-list-view::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stream-list-view {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* challenges-box  */
.challenges-box{
  position: absolute;
    right: 0;
    top: 6%;
    right: 3%;
    z-index: 1;
    width: 30%;
}

.challenges-box-play{
  bottom: 15%;
  left: 23%;
}

.play-challenges-box {
  bottom: 48%;
  left: 38%;
}

.play-challenges-box p{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.challenges-flag{
  left: 15%;
  z-index: 1;
  top: -4%;
}

.challenge-one{
  bottom: 29%;
  left: 30%;
  z-index: 1;
  width: 16%;
}

.challenge-two{
  bottom: 8%;
  left: 29%;
  z-index: 3;
  width: 18%;
}

.scrappy-shop-new{
  width: 54%;
}
/* /img/menu/menu-home.png */
.nav-menu-home{
  background-image: url('../../assets/images/navbar/color-home.png');
  width: 100px;
  height: 98px;
  object-fit: contain;
}

.active .nav-menu-home{
  background-image: url('../../assets/images/navbar/color-home.png');
  width: 100px;
  height: 98px;
  object-fit: contain;
}

.nav-menu-about{
  background-image: url('../../assets/images/navbar/color-about-us.png');
  width: 145px;
  height: 98px;
  object-fit: contain;
}

.active .nav-menu-about{
  background-image: url('../../assets/images/menu/menu-about-us.png');
  width: 145px;
  height: 98px;
  object-fit: contain;
}

.nav-menu-stream{
  background-image: url('../../assets/images/navbar/color-stream-stream.png');
  width: 98px;
    height: 88px;
  object-fit: contain;
}

.active .nav-menu-stream{
  background-image: url('../../assets/images/menu/menu-stream.png');
  width: 98px;
  height: 88px;
  object-fit: contain;
}

.nav-menu-news{
  background-image: url('../../assets/images/navbar/color-play-city.png');
  width: 104px;
  height: 88px;
  object-fit: contain;
}

.active .nav-menu-news{
  background-image: url('../../assets/images/menu/play-city-news.png');
  width: 104px;
  height: 88px;
  object-fit: contain;
}

.nav-menu-challenge{
  background-image: url('../../assets/images/navbar/color-challenge-bike.png');
  width: 94px;
  height: 103px;
  object-fit: contain;
}

.active .nav-menu-challenge{
  background-image: url('../../assets/images/menu/menu-challenges.png');
  width: 94px;
  height: 103px;
  object-fit: contain;
}

.nav-menu-shop{
  background-image: url('../../assets/images/navbar/color-scrappy-shop.png');
  width: 94px;
  height: 103px;
  object-fit: contain;
}

.active .nav-menu-shop{
  background-image: url('../../assets/images/menu/menu-scrappy-shop.png');
  width: 94px;
  height: 103px;
  object-fit: contain;
}


/* .nav-img{
  filter: grayscale(100%);
} */

/* .active img {
  filter: sepia(1) !important;
} */

.leaderboard-img-left{
  width: 80%;
}

.leaderboard-img-right{
  width: 82%;
}

.star-container{
  display: flex;
  flex-direction: row;
}
.scrapy-book{
  top: 56%;
  left: 59%;
  width: 135px;
}

.scrapy-book-img{
  margin: 4px 8px 0 0;
  width: 90px;
}

.scrapy-bag{
  top: 36%;
  left: 35%;
  width: 135px;
}


.scrapy-bag-img{
  margin: 4px 10px 0 0;
  width: 80px;
}

.scrapy-tshirt{
  top: 36%;
  left: 59%;
  width: 135px;
}

.scrapy-shirt-img{
  margin: 6px 10px 0 0;
  width: 90px;
}

.scrapy-flowerpot{
  top: 55%;
  left: 30%;
}

.scrapy-robo{
  top: 57%;
  left: 47%;
}

.scrapy-robo-two{
  top: 56%;
  left: 35%;
  width: 135px;
}

.scrapy-robot-img{
  margin: 1px 10px 0 0;
  width: 80px;
}

.download-btn {
  bottom: 9%;
  right: 20%;
  width: 400px;
}

.challenge-roads{
  width: 100%;
  bottom: -13%;
}

.challenges-flowers {
  right: 0;
  position: absolute;
  bottom: -2%;
  z-index: 0;
}

/* map css start */

.video-bg-play-city{
  background-image: url('../images/page-2/tv-slider.png');
  width: 700px;
  height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 28%;
  top: 31%;
  z-index: 2;
}


.video-bg-choice-play-city{
  background-image: url('../images/page-2/tv-slider-choice.png');
  width: 580px;
  height: 480px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 10%;
  top: 25%;
  z-index: 2;
}

.position-absolute-junk {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.video-small {
  width: 380px !important;
  right: 20%;
  height: 500px !important;
  position: absolute;
  top: 2% !important;
  border-radius: 14% !important;
}

.play-tv-box {
  bottom: 10%;
  right: 16.4%;
}

.playcity-box-img {
  width: 125px;
}

.play-tv-child{
  cursor: pointer;
}

.img-box-play-tv {
  position: absolute;
  top: 40%;
  left: -10%;
}

.img-box-choice-play-tv {
  position: absolute;
  top: 30%;
  left: -5%;
}

.img-box-play-tv img{
  width: 206px;
}

.play-tv-arrow-left {
  width: 80px;
  position: absolute;
  bottom: 9%;
  left: 15%;
}

.play-tv-arrow-right {
  width: 80px;
  position: absolute;
  bottom: 9%;
  left: 83%;
}

.video-active{
  border: 1px solid #cd1b92;
}

.signup-globe-revelead{
  bottom: 0;
}

.desktop-signup{
  position: absolute;
  top: 0%;
  left: 45%;
  z-index: 1;
  }

  .robo-2-img {
    width: 215px;
    right: -28%;
    top: 35%;
}

.play-city-nav{
  display: flex;
  justify-content: center;
}

.ladder-img{
  background-image: url('../../assets/images/page-1/ladder-img.png');
  background-repeat: no-repeat;
  width: 165px;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 30%;
  top: 21%;
}

.stream-tv-desktop{
    top: -17%;
    height: 100%;
    left: 0%;
    width: 190px;
}

.img-challenge-desktop{
  position: absolute;
    top: 11%;
    width: 185px;
}

.flag-image-desktop {
  position: absolute;
  left: 57%;
  width: 301px;
  height: 100%;
  top: 2%;
}

.sform-one p{
  font-size: 19px;
}


/* loader start  */

.loader-div {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #333;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader {
	position: relative;
	width: 10vw;
	height: 5vw;
	padding: 1.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader span {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	border-radius: 50%;
	background-color: #ff0;
}

.loader span:nth-child(1) {
	animation: loading-dotsA 0.5s infinite linear;
}

.loader span:nth-child(2) {
	animation: loading-dotsB 0.5s infinite linear;
}

@keyframes loading-dotsA {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(2vw);
	}
	100% {
		transform: none;
	}
}

@keyframes loading-dotsB {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(-2vw);
	}
	50% {
		transform: none;
	}
	75% {
		transform: translateY(-2vw);
	}
	100% {
		transform: none;
	}
}

/* loader end */

/* map css start */

@media only screen and (min-width: 1440px) { 
  .scrappy-shop-img-box{
    margin-top: -61px;
  }
}

@media only screen and (max-width: 1440px) {
  .globe-img-box {
    right: -7%;
}
.thread-box-one{
  bottom: 77%;
}
.play-city-tv{
  width: 493px !important;
}
.scrap-book-img {
  width: 50px;
}
.scrapy-book{
  top: 56%;
  left: 58%;
}
.ssh-right b{
  font-size: 13px;
}
.ssh-right p{
  font-size: 13px;
}
.scrapy-bag {
  top: 38%;
  left: 34%;
}
.scrapy-tshirt {
  top: 39%;
  left: 58%;
}
.scrapy-robo {
  top: 56%;
  left: 46%;
}
.scrapy-robo-two {
  top: 57%;
  left: 34%;
}
.download-btn {
  bottom: 8%;
  right: 19%;
  width: 249px;
}
}

@media only screen and (max-width: 1024px) {
  .globe-img-box{
    right: -39%;
  }
  .globe-img-box img {
    width: 45%;
}

/* signup page  */
.thread-box-one{
  bottom: 116%;
}
.flower-right-signup{
  right: -13%;
}
.flower-left-signup{
  left: -17%;
}
/* signup page  */
.challenges-flag {
  width: 248px;
}
.challenges-box {
  width: 335px;
  top: 24%;
}
.challenges-box-play {
  bottom: 11%;
  left: 14%;
}
.play-city-tv{
  width: 503px !important;
}

}

@media only screen and (max-width: 991px) { 
  .stream-bg{
    height: 150vh;
  }
  .scrappy-shop-img-box{
    width: 100%;
  }
}

@media only screen and (max-width: 768px) { 

  .leader-board-box {
    top: 24%;
    right: 0%;
}

.play-globe-arrow-box {
  top: 17%;
  right: 14%;
}

  .desktop-signup {
    top: -18%;
    left: 37%;
    z-index: 99;
  }

  .play-tv-arrow-right{
    bottom: 20%;
  }

  .play-tv-arrow-left{
    bottom: 20%;
    left: 11%;
  }

  .img-box-play-tv {
    position: absolute;
    top: 37%;
    left: -18%;
}

  .play-tv-box{
    bottom: 22%;
    right: 15%;
  }

  .playcity-box-img {
    width: 69px;
}

.img-box-play-tv img {
  width: 152px;
}

  .scrappy-shop-new{
    width: 100%;
  }

  .video-bg-play-city{
    width: 400px;
    left: 26%;
  }

  .video-small {
    width: 225px !important;
    right: 19%;
    top: 4% !important;
}

  /* signup & signin  */

  .globe-tv-slider-box {
    position: absolute;
    top: 36%;
    left: 6%;
}
.globe-scr-football-box {
  position: absolute;
  top: 17%;
  left: -23%;
}
/* .globe-playtv-box {
  left: 37%;
} */
.globe-tv-slider-box {
  top: 14%;
    left: 33%;
    width: 360px;
}

.globe-signup-box {
  position: absolute;
  top: 47%;
  left: 36%;
}
.arrow-img{
  display: block;
}
.globe-arrow-box {
  position: absolute;
  top: 65%;
  left: 39%;
  width: 200px;
}
.globe-img-box {
  right: -40%;
  top: 77%;
  z-index: 0;
}


/* .globe-signup-box-new {
    z-index: 99;
} */

  /* signup & signin end  */


  .news-home{
      background-image: url('../../assets/images/page-1/mobile-home.png');
      background-size: contain;
      width: 100vw;
      height: 100%;
    }
    .left-flower {
      left: -11%;
    }
    .right-flower {
      right: -21%;
    }
    .img-scooter {
      left: -14%;
    }
    .store-scrappy {
      right: -7%;
      width: 225px;
  }
  .img-playcity {
      position: absolute;
      bottom: -8%;
      right: 27%;
      width: 171px;
  }
  .img-ladder {
      bottom: -10%;
  }
  .stream-tv {
    right: -13%;
    top: -9%;
    width: 191px;
    z-index: 1;
  }
  
  /* signup page  */
  .thread-box-one{
    bottom: 148%;
    right: 0%;
  }
  .form-area{
    right: 22%;
  }

/* signup page end */

/* school signup start */
.school-palm-tree{
  position: absolute;
  top: 12%;
  left: -10%;
  width: 300px;
}
.robo-img {
  width: 146px;
}
.cycle-img {
  left: 41%;
}
.cycle-img {
  bottom: 11%;
  left: 36%;
  width: 115px;
}
.cycle-img{
  left: 48%;
  width: 136px;
}

.bg-paper-top{
  height: 109vh !important;
}
.bg-paper-bottom{
  height: 88vh !important;
}
/* school signup end */
.globe-playtv-box {
  position: absolute;
  top: 77%;
  left: 35%;
}
.form-paper{
  top: -10%;
}
.flower-left-signup-football{
 display: none;
}
.flower-left-signup-baseball{
  display: none;
}
.img-scooter-bike {
  left: -4%;
  width: 313px;
}
.flag-image {
  width: 125px;
    z-index: 1;
    top: -1%;
    left: 203%;
}
}


@media only screen and (max-width: 425px) {

  .mobile-nav{
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    justify-content: start !important;
  }

  /* .globe-signup-box-new{
    top: -59%;
    width: 205px;
  } */

  .desktop-signup {
    top: -47%;
}

  .leader-board-box {
    top: 45%;
    left: -78%;
}

  .play-globe-arrow-box {
    top: 9%;
    right: 13%;
    z-index: 4;
}

  .play-city-globe-img{
    width: 100%;
  }

  .play-tv-arrow-right{
    bottom: 25%;
    left: 83%;
    width: 42px;
  }

  .play-tv-arrow-left {
    bottom: 25%;
    left: 14%;
    width: 42px;
}

  .scrappy-shop-img-box{
     margin-top: 10px;
  }

  .download-btn{
    width: 122px;
  }

  .ssh-right p {
    font-size: 5px;
}

.ssh-right b {
  font-size: 9px;
}

.scrap-book-img {
  width: 20px;
}

.scrapy-book {
  top: 56%;
  left: 58%;
}

.scrapy-bag {
  top: 38%;
  left: 34%;
}

.scrapy-tshirt {
  top: 39%;
  left: 58%;
}

.scrapy-flowerpot {
  top: 53%;
  left: 22%;
}

.scrapy-robo {
  top: 54%;
  left: 44%;
}

.scrapy-robo-two {
  top: 57%;
  left: 34%;
}

  .play-tv-box{
    bottom: 26%;
  }

  .playcity-box-img {
    width: 59px;
  }

  .leaf-green{
    display: none;
  }

  .video-bg-play-city{
 left: 2%;
  }

  .video-bg-play-city{
    width: 350px;
  }

  .video-small {
    width: 191px !important;
    right: 19%;
    height: 406px !important;
    position: absolute;
    top: 5% !important;
    border-radius: 14% !important;
}

  /* signup & signin  */
.globe-tree-box {
  position: absolute;
  top: -12%;
  left: -73px;
}
.globe-signup-img-desktop{
display: none;
}
.globe-playtv-box {
  position: absolute;
  top: 90%;
  left: 19%;
  z-index: 2;
}
.globe-tv-slider-box {
position: absolute;
top: 14%;
left: 10%;
}
.globe-scr-football-box {
  position: absolute;
    top: -4%;
    left: -41%;
}
.globe-scr-football-box  img{
width: 130px;
}
.globe-signup-box {
position: absolute;
top: 47%;
left: 20%;
}
.globe-arrow-box {
position: absolute;
top: 66%;
left: 31%;
width: 136px;
transform: rotate(12deg);
}
.globe-img-box {
position: absolute;
top: 75%;
right: -106%;
display: block;
}
.globe-img-box img {
width: 28%;
}
.signup-btn-img {
left: 30%;
margin-top: 20px;
}
.scrappy-profile {
  top: 13%;
  left: 24%;
}

.scrappy-signup-btn-one{
  width: 75%;
}
.scrappy-signup-btn-two{
  width: 75%;
}

.forgot-password {
  bottom: -12%;
}

/* signup & signin  */


  .right-flower {
      right: -43%;
  }
  .left-flower {
      left: -60%;
      top: 10%;
      transform: rotate(2deg);
  }   
  .stream-tv {
    right: -7%;
    top: -5%;
    width: 158px;
    z-index: 1;
  }
  .img-scooter {
      left: -13%;
      width: 160px;
  }
  .img-playball{
      bottom: 27px;
  }
  .store-scrappy {
      right: -12%;
      width: 117px;
  }
  .img-playcity {
    position: absolute;
    bottom: -1%;
    right: 32%;
    width: 77px;
  }
  .img-challenge{
      width: 103%;
  }
  .flag-image {
    width: 125px;
    z-index: 1;
    top: 5%;
    left: 171%;
  }
  .img-ladder {
      bottom: -2%;
  }
  /* signup start  */
  .thread-box-one {
    bottom: 336%;
  }
  .form-area{
    right: 2%;
  }
  .submit-btn{
    bottom: -22%;
  }
  .submit-btn {
    bottom: -35%;
}
.form-area-login{
  right: -12%;
}
.fsb-para{
  font-size: 10px;
}
  /* signup end  */

  
  /* school signup start */
  .school-palm-tree {
    position: absolute;
    top: 2%;
    left: -21%;
    width: 168px;
    transform: rotate(1deg);
   }
   .scrappy-signup-title{
    top: 18%;
    left: 53%;
    font-size: 16px !important;
   }
   .scrappy-signup-btn-one{
    top: 33%;
    left: 63%;
   }
   .scrappy-signup-btn-two{
    top: 51%;
    left: 63%;
   }
   .robo-img{
    right: -8%;
   }
   .cycle-img {
    left: 32%;
    width: 137px;
    }
    .scrappy-signup-title{
      font-size: 10px;
    }
    .cycle-img {
      bottom: -1%;
    }

    .fsb-btn-one{
      min-width: 200px;
    }
    
    .fsb-btn-two{
      min-width: 200px;
    }

    .form-center-thankyou{
      top: 33%;
    }

  .mob-img-height{
    height: 90vh !important;
  }
   /* school signup end */
   .flag-image-play-city {
    position: absolute;
    z-index: 1;
    left: 26%;
    bottom: 89%;
    width: 49%;
}
.play-challenges-box p{
  font-size: 15px;
}
.challenges-flag{
  z-index: -1;
}

.play-city-absolute {
  bottom: 339%;
}

.flower-left-signup-football{
  bottom: -42%;
}
.img-scooter-bike {
  left: -4%;
}
.flower-left-signup-baseball {
  bottom: -20%;
}
.img-scooter-bike{
  width: 116px;
}

}


@media only screen and (max-width: 375px) {

  .desktop-signup {
    top: -47%;
}


.play-globe-arrow-box {
  top: 11%;
}

  .play-tv-arrow-right {
    bottom: 28%;
  }

  .play-tv-arrow-left {
    bottom: 28%;
    left: 12%;
  }

  .playcity-box-img {
    width: 51px;
 }

 .play-tv-box {
  bottom: 29%;
}

 /* signup & signin  */
  .globe-img-box {
    top: 76%;
    right: -128%;
  }
  .treesbox {
    width: 256%;
  }

  .video-bg-play-city {
    width: 300px;
}
.video-small {
  width: 161px !important;
}
  /* signup & signin  */

  .stream-tv {
    right: -1%;
    top: -2%;
    width: 120px;
    z-index: 1;
  }
  .img-ladder {
      bottom: 11%;
  }
  /* signup start  */
  .submit-btn{
    bottom: -16%;
    left: 11%;
  }
  .submit-btn {
    bottom: -34%;
  }
  /* signup end  */
  .forgot-password {
    bottom: -10%;
}
}

@media only screen and (min-width: 3300px) { 
.ladder-img{
  left: 41%;
}
}

@media only screen and (max-width: 320px) {

  .desktop-signup {
    top: -51%;
  }
  

  /* signup & signin  */
  .globe-signup-box {
    position: absolute;
    top: 45%;
    left: 10%;
}
.globe-arrow-box {
  top: 64%;
}
.globe-img-box {
  top: 73%;
  right: -158%;
}
.form-paper{
  margin-top: 0px !important;
}
.otp-img{
  width: 45px;
}
.submit-btn {
  bottom: -14%;
}
.submit-btn-login{
  bottom: -27%;
}
  /* signup & signin  */

  .stream-tv {
      top: 0%;
  }

  /* school signup start */
.school-palm-tree{
  position: absolute;
    top: 27%;
    left: -5%;
    width: 100px;
}
.robo-img{
  width: 82px;
}
.cycle-img {
  left: 31%;
}
.scrappy-signup-title{
  font-size: 11px !important;
}
/* school signup end */
.leader-board-box {
  top: 50%;
  left: -72%;
}
}

